<template>
  <section>
    <TopBar/>
    <Header/>
    <NavBar/>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>News &amp; Events</li><span class="arrow">></span>
                  <li></li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <HomeAnnCard :ann="ann" v-for="(ann,id) in documents" :key="id"/>
                </div>
          <div class="sidebar">
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script>

import Footer from '../../components/page/Footer.vue'

import TopBar from '../../components/page/TopBar.vue';
import Header from '../../components/page/Header.vue';
import NavBar from '../../components/page/NavBar.vue';
import RecentPosts from '../../components/page/RecentPosts.vue';
import getCollection from '../../composables/getCollection'
import HomeAnnCard from "../../components/page/HomeAnnCard.vue"
export default {
    name: 'ViewAnn',    
    props: ["id"],  
    components: { Footer, TopBar, Header, NavBar, RecentPosts,HomeAnnCard },
    setup(props){
        const {documents} = getCollection('announcements')

        return {documents}
      
  }
}
</script>

<style scoped>

.page :deep(li){
  margin-left: 2rem;
}
.page :deep(h3){
    margin-top: 2rem;
}
.page :deep(h5){
    padding-top: 0.5rem;
}
.page :deep(h5 span){
    font-weight: 400;
}
@media (min-width:768px) {
    .page :deep(h3){
    margin-top: 1rem;
}
}
</style>